


:root{
	--mainColF:#e30613; 
	--colwhite:#fff;
	--colBackGround:#232323;
	/* --collabel:rgb(227, 6, 19); */
}

/*=============================================
=            business contact form           =
=============================================*/

/* rgb(227, 6, 19); 
#e30613*/




/* ! business contact form*/
.block-contact-business{
	display: none;
	position: absolute;
	top:0px;
	z-index: 2000;
	left: 0;
	right: 0;
	margin: auto;
	transition: 1s;
	/* border: 2px solid white; */
}
.wrapper{
transition: opacity 150ms linear ;

}
.wrapper-opacity{
	opacity: 0.1;
	overflow: hidden;
    /* background-color: #fff; */
}

.display-block{
	display: block;
	overflow: hidden;
}
.form-contact-business{
	/* border: 1px solid; */
	margin-top: 10px;
	padding: 2em 0;
	background-color: #232323;
	position: relative;
	
	/* border-bottom: 0 none; */
	box-shadow: 0 1px 3px rgba(255, 255, 255, 0.9);
	border: 2px solid white;
}
textarea:focus, input:focus{
    outline: none;
}

/* close form button */
#close-form{
font-size:2em;
position: absolute;
top:10px;
right: 10px;
color:#fff;
transition: 500ms ease forwards;
}
#close-form:hover{
	color:#e30613;
}

/* submit Button */
#submitBusiness{
	margin-top: 1.2em;
}
/* ! for form  End*/
/*=====  End of business contact form  ======*/
/* form style */
.bn-please-call-me{
	color:#e30613;
}

.business-error{
	display: none;
}
.input-f2 {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 1em;
	width: calc(100% - 2em);
	/* vertical-align: top; */
	padding-top: 1em;
	/* border: 1px solid yellow; */
	/* height: 50px; */
	margin: 0;
	margin-top:.6em;
}
.input-f2-field {
	width: 100%;
	padding: 0.8em 0.5em;
	background: transparent;
	border: 2px solid;
	color: #fff;
	-webkit-transition: border-color 0.25s;
	transition: border-color 0.25s;
}

.input-f2-label {
	font-size: .8em;
	width: 100%;
	position: absolute;
	top: -10px;
	right: 0;
	text-align: left;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	-webkit-transform: translate3d(0, 3em, 0);
	transform: translate3d(0, 3em, 0);
	
}
.input-f2-label-content {
	position: relative;
	display: block;
	width: 100%;
	font-weight: 400;
	color: #fff;
	left:10px;
}

.input-f2-label-content::after {
	content: attr(data-content);
	position: absolute;
	top: -200%;
	left:-10px;
	color: #e30613;
	font-weight: 800;
	width: 100%;
	

}

.input-f2-field:focus,
.input-f2-filled .input-f2-field {
	border-color: #e30613;
}

.input-f2-field:focus + .input-f2-label,
.input-f2-filled .input-f2-label {
	-webkit-animation: anim-chisato-1 0.25s forwards;
	animation: anim-chisato-1 0.25s forwards;
}

.input-f2-field:focus + .input-f2-label .input-f2-label-content,
.input-f2-filled .input-f2-label-content {
	-webkit-animation: anim-chisato-2 0.25s forwards ease-in;
	animation: anim-chisato-2 0.25s forwards ease-in;
}

.checkbox-field{
/* border:1px solid red !important; */
/* color: red !important; */
/* margin-top: 1.8em; */
text-align: left;
}

@-webkit-keyframes anim-chisato-1 {
	0%, 70% {
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	71%, 100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes anim-chisato-2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	70%, 71% {
		-webkit-transform: translate3d(0, 125%, 0);
		transform: translate3d(0, 125%, 0);
		opacity: 0;
		-webkit-animation-timing-function: ease-out;
	}
	100% {
		color: transparent;
		-webkit-transform: translate3d(0, 200%, 0);
		transform: translate3d(0, 200%, 0);
	}
}

@keyframes anim-chisato-1 {
	0%, 70% {
		-webkit-transform: translate3d(0, 3em, 0);
		transform: translate3d(0, 3em, 0);
	}
	71%, 100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes anim-chisato-2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	70%, 71% {
		-webkit-transform: translate3d(0, 125%, 0);
		transform: translate3d(0, 125%, 0);
		opacity: 0;
		-webkit-animation-timing-function: ease-out;
	}
	100% {
		color: transparent;
		-webkit-transform: translate3d(0, 200%, 0);
		transform: translate3d(0, 200%, 0);
	}
}


/* .fusion-main-menu .sub-menu {

    position: absolute;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-in;
    top: 50px;

} */
/* --------------------------------------
=========================================
COLOR : RED 

MAIN COLOR CODE: #e30613

DEEP COLOR CODE: #be2a2a

=========================================
----------------------------------------- */
:root{
    --mainCol:#e30613;
}


.color-overlay {
    background: rgba(0, 0, 0, 0.55);
}

.standard-button {
    background: #e30613;
}

.standard-button2 {
    background: #e30613;
}

.standard-button2:hover {
    background: #be2a2a;
}

.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.open .dropdown-toggle.btn-primary {
    background: #be2a2a;
    border-color: inherit;
}

.colored-line,
.colored-line-left {
    background: #e30613;
}

.main-color {
    color: #e30613;
}

.color-bg {
	background: #e30613;
	color: #FFFFFF;
}

.features .feature:hover .icon-container .icon {
    background: #e30613;
    color: #ffffff;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0px 0px 0px 2px #e30613;
            box-shadow: 0px 0px 0px 2px #e30613;
}

.features .feature .icon-container .icon {
    border: 1px solid #e30613;
    color: #e30613;
}

.feature-list li i {
    color: #e30613;
}

.services .single-service .service-icon,
.services .single-service h3 {
    color: #e30613;
}

.screenshots .owl-theme .owl-controls .owl-page span {
    background: #e30613;
}

footer .contact-button {
    border: 2px solid #e30613;
    color: #e30613;
}

footer .contact-button:hover {
    background: #e30613;
}

footer .contact-box .expanded-contact-form .input-box:active,
footer .contact-box .expanded-contact-form .textarea-box:active,
footer .contact-box .expanded-contact-form .input-box:focus,
footer .contact-box .expanded-contact-form .textarea-box:focus {
    border: 1px solid #e30613;
}

footer .social-icons li a:hover {
    color: #e30613;
}
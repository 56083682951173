:root{
    --mainCol:#e30613;
    --text:#808080;
    --colwhite:#fff;
    --successCol:green;
    --colbackmain:#111111;
}

.box{
    border: 1px solid white;
}
body{
    font-family: 'Ubuntu', sans-serif;
}
h1, h2{
    font-family:  'Ubuntu', sans-serif;
}
img{
    max-width: 100%;
}
a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

/*=============================================
=            Header section            =
=============================================*/
/* nav section */
.navbar-header{

padding: 0;

}
.navbar-brand {
    margin: 0;
    padding: 0;
    opacity: 1;
     transition: opacity 0.25s ease-in;
     height: auto;
}
.navbar-brand:hover{
    opacity: .75;
    transition: all 0.25s ease-in-out; 
}


.navbar-static-top{
    
  /* 
  !nav */
  padding: 1em 0;
  background: #111111;
  border: none;
  margin-bottom: 0 !important;
  -webkit-backface-visibility: hidden;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
          box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
}

.navbar-static-top .navbar-header img {
  width:100%;
  -webkit-backface-visibility: hidden;
  /* margin: 0; */
  /* margin-left: 5px; */
}
.navbar-brand img{
    height: 55px;
    width: 195px !important;
    margin-left: 1em;
} 

.navbar-static-top .main-navigation .current a {
  color:#fff;
  position: relative;
  outline: none;
}

.navbar-static-top .main-navigation li a {
  line-height: 2;
  font-weight: 400;
  font-size: .8em;
  text-transform: uppercase;
  color: #c8c8c8;
  letter-spacing: .1em;
  outline: none;
}

header .only-logo .navbar {
    background: none;
    padding: 60px;
}
.logoicon{
    height: 10em;
    padding: 0;
}
/* call to action nav */
#cta-nav-ink{
    /* background-color: #e30613; */
    border: 2px solid #fff;
    font-weight: bold;
    border-radius: 4px;
    transition: all ease 0.25s;
    margin:5px 0;
    padding-top: 10px;
    padding-bottom: 10px;
}
#cta-nav-ink:hover{
    background-color: #fff;
    color: #000;
}
/* 
!26.03.2018 */
.navbar{
min-height: 80px;
}
.navbar-toggle{
    margin: 5px 15px 5px 0;
}


/* CTA */
.first-cta,
.second-cta{
    /* text-transform: uppercase; */

}
.second-cta{
    background-color: #fff !important;
    color: #e30613;
    /* margin-left: 2em; */
}
.first-cta:hover{
/* border: 2px solid #fff; */
  color: #e30613;
}
.second-cta:hover{
/* border: 2px solid #e30613; */
background-color: #e30613 !important;
    color: #fff;

}

#loyal-button-bussines{
    /* margin-left: .1em; */
    /* border: 1px solid green; */
    
    /* padding-left: 10px; */
    
}
.cta-hero{
     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: .2em 1.2em 4em;
}
#download-button{
    margin-left: 1.2em;
}
/*=====  End of Header section  ======*/


/*=============================================
=            Amazing Features            =
=============================================*/
.features .phone-image img {

    margin-top: 50px;

}


/*=====  End of Amazing Features  ======*/



/*=============================================
=            LoyalTab at a Glance block            =
=============================================*/

#feature-list{
    width: 100%;
    text-align: left;
}
#feature-list td, #feature-list th {
    border: 1px solid #ddd;
    padding: .5em;
    
}
#feature-list tr:nth-child(even){
    background-color: #232323;
}
#feature-list th {
    text-align: center;
    font-size: 1.2em;
    background-color: #e30613;
}
#feature-list tr td:nth-child(1) i{
margin-right: .5em;
}

#feature-list tr td:nth-child(2),
#feature-list tr td:nth-child(3) {
    text-align: center;
    /* line-height: 1; */
    margin: 0;
    padding: 0;
}
#feature-list tr td:nth-child(3){
    color:#32db64;
}
#feature-list tr td:nth-child(2){
    color:#e30613;
}
#feature-list tr td:nth-child(3) i{
    font-size: 1.2em;
}
#feature-list tr td:nth-child(2) i{
    font-size: 1.4em;
}
/* .col-md-6 .fadeInRight{
    width: 10% !important;
}
.col-md-6 .fadeInLeft{
    width: 60% !important;
} */
/*=====  End of LoyalTab at a Glance block  ======*/


/*=============================================
=            How it Works              =
=============================================*/

.list-how-works{
    text-align: left;
    margin-top: 2em;
    margin-left: 1em;
    list-style: none; 
    counter-reset: li;
}
.list-how-works li::before {
  content: counter(li)".";
  color: #fff;
  display: inline-block; 
  width: 1em;
  margin-left: -1em
}

 .list-how-works li {
     counter-increment: li;
    }
/*=====  End of How it Works    ======*/




/*=============================================
=            Video section            =
=============================================*/
.video-section{
    padding-top: 50px;
}
iframe{
    border: 0;
    /* opacity: .2; */
    
} 
.ytp-cued-thumbnail-overlay-image,
.video{
    /* height: 200px; */
    /* background-size:cover; */
    background-image: url(../img/videobackground/v-background.jpg) ;
}
#brief3{
    background-color: #000 !important;
}
/* .a{
    height: 200px;
} */



/*=====  End of Video section  ======*/


/*=============================================
=            Parteners section            =
=============================================*/
.testimonials .feedback .image{
    border-radius:5px  ;
    /* border: 1px solid #e30613; */
    width: auto;
    height: auto;
    width: 40%;
    box-shadow: 0px 0px 0px 2px #e30613;
}

/* client name */
.name{
font-size: 1.4em;
font-weight: bold;
}

.testimonials .feedback .company-info{
font-size: 1em;
}

.testimonials .feedback .message{
    font-size: 1em;
    margin-top: 20px;
}
.button-client{
    padding: 10px 20px 10px 20px;
}

/*=====  End of Parteners section  ======*/

/*=============================================
=            Feedback-Testimonials section            =
=============================================*/
.feedback-testimonials{
    /* border:2px solid; */
    background: url(../img/background/bg-4.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
    background-size: cover;
    color: #ffffff;
}
.color-overlay-feedback{
    background: rgba(0, 0, 0, 0.7);
    padding: 5em 1em;
}

.imageFeed img{
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    border: 2px solid;
}
.name-feed-test{
    margin: .1em auto;
    font-weight: bold;
    letter-spacing: .2em;
}
.possition-feed{
    margin: .05em auto;
}

.start-feed{
    /* border: 1px solid red; */
    color: #e30613;
    margin-top: 0;
    
}

.first-tfeed,
.second-tfeed{
    /* border: 1px solid; */
    align-self:center;
}
.first-tfeed{
    min-width: 200px;
}
.second-tfeed{
    padding: 1em 2em;
    text-align: left;
    font-size: 1.2em;
}
.second-tfeed p{
    position: relative;
}
.second-tfeed p::after{
    position: relative;
    content: '\201D';
    font-size: 3em;
    line-height: 0;
    color:  #e30613;
    bottom: -22px;
    right: 0;
}


.second-tfeed p::before{
    position: relative;
    content: '\201C';
    font-size: 3em;
    line-height: 0;
    color: #e30613;
    left: 0;
    top: 15px;;
    
}

.item{
    display: flex;
}
/* #feedBackT {
    display: flex;
    align-items: center;
} */


/* .feedback-testimonials{

}
 */

/*=====  End of Feedback-Testimonials section  ======*/



/*=============================================
=            Subscribe Section            =
=============================================*/
.success-subscribe,
.success-subscribe:hover,
.success-business,
.success-business:hover{
    color: #fff !important;
    background-color:  green !important;
}


/*=====  End of Subscribe Section  ======*/





/*=============================================
=            Footer section            =
=============================================*/
.copyright a{
    color: #808080;
}
.copyright a:hover{
    color: #e30613;
}
.bottom-link-footer a{
color: #fff;
}
/*=====  End of Footer section  ======*/


/*=============================================
=            Cookie Section            =
=============================================*/



.cookie-main-wrap{
display: none;
position: fixed;
padding: 2em 1em;
width: 100%;
z-index: 3000;
background-color: rgba(0, 0, 0, 0.96);

}

.cookie-main-wrap a{
    color: #e30613;
}
.cookie-main-wrap a:hover{
    color: #fff;
}
.cookie-main-wrap p{
margin-top: 0;
margin-bottom: 0;
}
.cookie-main-wrap h2{
font-size: 1.5em;
}
.cookie-links{
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    align-items: center;

}
/* .cookie-links a{
    display: block;
} */
.agree ,
.decline {
    /* border: 1px solid; */
    margin: .5em .5em;
    padding: .3em 1em;
    display: flex;
    align-items: center;
    
    border-radius: 5px;
    
}
.agree i{
    font-size:1.5em;
    
}
.cookie-main-wrap .agree{
    font-size: 1.2em;
    font-weight: bold;
    background-color: #e30613;
    color: #fff;
    transition: 250ms;
}
.cookie-main-wrap .agree:hover{
    background-color: #fff;
    color: #e30613;

}

.decline i{
font-size:1.5em;
}

.cookie-main-wrap .decline {
    font-size: 1em;
    /* border: 1px solid #fff; */
    color: #fff;
    
}

.cookie-main-wrap .decline:hover{
    /* border: 1px solid #e30613; */
    color: #e30613;
    /* text-decoration:line-through; */
    
}

.showcookieSection{

display: block;
animation: cookieshow 1s linear forwards;

}
.hidecookie{
    display: block;
    animation: cookiehide 1s linear ;
}
@keyframes cookieshow {
 0% {
  display: block;
  bottom:-20em;
  opacity: .2;
 }
 25% {
  bottom:-8em;
  opacity: .4;

 }
 50% {
  bottom:-5em;
  opacity: .5;

 }
 75% {
  bottom:-2.5em;
  opacity: .8;

 }
  85% {
  bottom:2.5em;
  opacity: .9;

 }
 100% {
  bottom:0;
  opacity: 1;

 }
}
@keyframes cookiehide {
 0% {
     /* display: block; */
  bottom:0 ;
 }
 15% {
     /* display: block; */
  bottom:2.5em ;
 }
 25% {
  bottom:-10em ;
 }
 50% {
  bottom:-15em;
 }
 75% {
  bottom:-18em;
 }
 100% {
     opacity: .2;
  bottom:-20em;
  display:none;
 }
}
/*=====  End of Cookie Section  ======*/




/*=============================================
=            Download the app            =
=============================================*/
/* .linkButtonIcon{
    display: flex;
} */


/*=====  End of Download the app  ======*/

/* go top button */
#go-top{
    position: fixed;
    bottom: 20px;
    right: -95px;
    z-index: 5000;
  }
  #go-top a{
    opacity: .4;
    outline: none;
    width: 10%;
  }
  #go-top a img{
        width: 80%;
  }
  #go-top  a:hover{
    opacity: 1;
  }

.gotopButton{
    position: relative;
    /* border: 1px solid; */
}


/* !15.102018
 */

.fetaure-details h4{
    /* border: 1px solid white; */
    min-height: 60px;
    display:flex;
    align-items: center;
    justify-content: center;
    /* vertical-align: middle; */
}
.fetaure-details p{
    /* border: 1px solid white; */
   /* width: 80%; */
   /* padding: 1em; */
   /* margin-top: 20px; */
}

.icon-container{
    /* border: 1px solid white; */

}
.main-color{
    /* border: 1px solid white; */

}

/*=============================================
=            Media             =
=============================================*/
@media  only screen and (max-width: 991px) {
/* .navbox{ */
    /* position: relative; */
    /* border: 1px solid;  */
    /* width: 100%; */
    /* margin-left: 1em;
    margin-right: 1em; */

/* } */
/* .navbar-static-top .navbar-header img {
 
} */
    /* .navbar-header{
    position: relative;
    left:0;
    right: 0;
    margin: 0 auto;
} */
}
@media  only screen and (max-width: 768px) {


.testimonials .feedback .image{
    width: 40%;
}
/* navbar section */
.navbar{
min-height: 40px;
}
.navbar-static-top .navbar-header img {
  width:80%;
}
.navbar-static-top{
    padding: .5em 0;
}

}

@media  only screen and (max-width: 650px) {
    /* What people are saying */
    .item{
        flex-direction: column;
        justify-content: center;
    }

    .second-tfeed {
    padding: 1em .2em;
    text-align: justify;
    }
}

@media  only screen and (max-width: 450px) {
 .testimonials .feedback .image{
    width: 80%;
}
#feature-list th {
    font-size: 1em;
}
#feature-list td {
    font-size: 1em;
    /* word-wrap: break-word; */
    /* padding: 1em; */
}
#feature-list tr td:nth-child(3) i{
    font-size: 1em;
}
#feature-list tr td:nth-child(2) i{
    font-size: 1.2em;
}
}

/*=====  End of Media   ======*/

